<template>
    <div class="mx-2 my-4 pb-10">
    <v-breadcrumbs
      :items="nav_items"
      divider=">"
    ></v-breadcrumbs>
    </div>
</template>

<script>

export default {
    props: {
        nav_items:{type:Array}
    },
    data() {
        return {
            
        }
    }

}
    
</script>
