<template>
  <v-list-item-group
      v-model="group"
      active-class="deep-white--text text--accent-4"
  >
      <v-list-item
          v-for="(item, i) in permittedItems"
          :key="i"
          class="my-3 p-0"
      >
          <v-list-group
              v-if="item.subItems || Array.isArray(item.subItems)"
              :value="true"
              no-action
              prepend-icon=""
              class="sub-item-list"
              sub-group
          >
              <a
                  v-if="item.href != ''"
                  :href="`${item.href}`"
                  class="px-4 d-flex"
              >
                  <v-list-item-icon class="mr-3">
                      <svg-vue :icon="item.icon"></svg-vue>
                  </v-list-item-icon>
                  <v-list-item-content>
                      {{ item.text }}
                  </v-list-item-content>
              </a>
              <router-link v-else :to="`${item.route}`" class="px-4 d-flex">
                  <v-list-item-icon class="mr-3">
                      <svg-vue :icon="item.icon"></svg-vue>
                  </v-list-item-icon>
                  <v-list-item-content>
                      {{ item.text }}
                  </v-list-item-content>
              </router-link>

              <v-list-item
                  v-for="(subItem, i) in item.subItems"
                  :key="i"
                  class="sub-item my-3"
                  link
              >
                  <a
                      v-if="subItem.href"
                      target="_blank"
                      :href="`${subItem.href}`"
                      class="px-4 d-flex"
                  >
                      <v-list-item-content>
                          {{ subItem.text }}
                      </v-list-item-content>
                  </a>
                  <router-link
                      v-else
                      :to="`${subItem.route}`"
                      class="px-4 d-flex"
                  >
                      <v-list-item-content>
                          {{ subItem.text }}
                      </v-list-item-content>
                  </router-link>
              </v-list-item>
          </v-list-group>

          <router-link
              v-else-if="item.redirect_route"
              :to="`/${item.redirect_route}/${item.route}`"
              class="px-4 d-flex"
          >
              <v-list-item-icon class="mr-3">
                  <svg-vue :icon="item.icon"></svg-vue>
              </v-list-item-icon>
              <v-list-item-content>
                  {{ item.text }}
              </v-list-item-content>
          </router-link>
          <router-link
              v-else
              :to="`/${usertype}/${item.route}`"
              class="px-4 d-flex"
          >
              <v-list-item-icon class="mr-3">
                  <svg-vue :icon="item.icon"></svg-vue>
              </v-list-item-icon>
              <v-list-item-content>
                  {{ item.text }}
              </v-list-item-content>
          </router-link>
      </v-list-item>
      <!-- <v-list-item>
          <router-link 
          :to="`/${usertype}/e-library`"
          class="px-4 d-flex">
              <v-list-item-icon class="mr-3">
                  <svg-vue icon="lms"></svg-vue>
              </v-list-item-icon>
              <v-list-item-content> E-Library </v-list-item-content>
          </router-link>
      </v-list-item> -->
      <v-list-item>
        <!-- <router-link to="/lms/open/" class="px-4 d-flex"> -->
          <a href="/lms/open/" class="px-4 d-flex">
              <v-list-item-icon class="mr-3">
                  <svg-vue icon="lms"></svg-vue>
              </v-list-item-icon>
              <v-list-item-content> LMS HOME </v-list-item-content>
          </a>
          <!-- </router-link> -->
      </v-list-item>
  </v-list-item-group>
</template>

<script>
import UserAPI from "../../apis/User";
import AdmissionRoundAPI from "../../apis/AdmissionRound";
export default {
  props: ["items", "roles", "permissions", "usertype", "environment"],
  data() {
      return {
          allItems: this.items,
          permittedItems: [],
          group: null,
          myPermissions: this.permissions,
          myRoleNames: this.roles,
          app_url: process.env.MIX_APP_URL, //http://sandbox-tertiary-lms.com/
      };
  },
  methods: {
      pruneSideBarItemsWithAdmissionRequirementsCheckList() {
          let prunedItems = [];
          UserAPI.auth().then((response) => {
              AdmissionRoundAPI.getAdmissionRoundByID(
                  response.data.data.admission_round_id
              ).then((res) => {
                  let checkList = res.data.data.checkList;
                  //console.log(checkList)
                  this.items.forEach((item) => {
                      //  console.log(item);
                      let check = item.check;
                      if (`check == ""||checkList.${check}`) {
                          prunedItems.push(item);
                      }
                  });
              });
              console.log(prunedItems);
          });
          this.allItems = prunedItems;
      },

      loadRolesAndPermissionNames() {
          // UserAPI.getMyRolesAndPermissions(this.myPermissions)
          //   .then(response=>{
          // this.myRoleNames = response.data.roleNames;
          // this.myPermissions = response.data.permissionNames;
          this.allItems.forEach((item) => {
              if (this.userHasPriviledgeTo(item.permissions)) {
                  this.permittedItems.push(item);
              }
          });

          //   console.log(this.myRoleNames)
          // });
      },

      initialize() {
          //this.pruneSideBarItemsWithAdmissionRequirementsCheckList();
          this.loadRolesAndPermissionNames();
      },

      userHasPriviledgeTo(requiredPermissions) {
          // console.log('user type: '+this.usertype);
          return (
              this.myRoleNames.includes("Super Admin") 
              || ( this.environment==='institute'  && (this.myRoleNames.includes("Director")||this.myRoleNames.includes("Deputy Director")))
              ||  requiredPermissions.length == 0 
              ||this.arraysHaveCommonElements(
                      this.myPermissions,
                      requiredPermissions
                  )
          );
      },

      // check if two arrays have common elements
      arraysHaveCommonElements(arr1, arr2) {
          let commonElements = arr1.filter((element) =>
              arr2.includes(element)
          );
          if (commonElements.length > 0) {
              return true;
          } else {
              return false;
          }
      },
  },
  created() {
      this.initialize();
  },
};
</script>
