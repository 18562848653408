<template>
  <v-app id="inspire">
  <v-sheet color="white" height="600" align="center" justify="center" dark>
    <v-container>
            <v-avatar
                color="indigo"
                size="200"
                style="margin-top: 120px"
                >
                <img
                    :src="bucketUrl + lecturer.picture_url +  '?t=' + new Date().getTime()"
                    alt=""
                    v-if="lecturer && lecturer.picture_url"
                >
                <img
                    src="images/profile_male.png"
                    alt=""
                    v-else-if="lecturer && lecturer.gender === 'Male'"
                >
                <img
                    src="images/profile_female.png"
                    alt=""
                    v-else
                >
            </v-avatar>
            <div>
                <v-list-item color="black" two-line v-if="lecturer">
                    <v-list-item-content>
                        <v-list-item-title class="headline black--text" >{{lecturer.title}} {{lecturer.firstname}} {{lecturer.lastname}} [{{lecturer.staff_number}}]</v-list-item-title>
                        <!--<v-list-item-subtitle>{{lecturer.staff_number}}</v-list-item-subtitle> -->
                        <v-list-item-subtitle class="black--text">{{lecturer.appointment_type}} {{lecturer.rank ? lecturer.rank.name : ''}}, {{lecturer.department.name}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <router-link :to="{ name: 'Lecturer Profile', params: {user: lecturer} }" v-if="lecturer">
                   <v-btn class="mt-5" light depressed color="primary"> View Profile </v-btn>
                </router-link>
            </div>
    <!-- <OverviewCards></OverviewCards> -->
    </v-container>
    <v-container >
                <v-row :col="6">
                    <v-col>

                            <v-card outlined elevation="2" color="#ffffff" light height="290">
                            <v-card-title>Today's Lectures</v-card-title>
                                <v-simple-table>
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th class="text-left">
                                            Course
                                        </th>
                                        <th class="text-left">
                                            Day & Time
                                        </th>
                                        <th class="text-left">
                                            Venue
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(lecture,l) in lectures" :key="l">
                                            <td>{{lecture.course.course_code}}</td>
                                            <td>{{lecture.lectureDay.week_day}} - {{lecture.lecturePeriod.start_time}}</td>
                                            <td>{{lecture.lectureVenue.venue_code}}</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
 <!--
                                <v-card-actions class="justify-end py-5">
                            <v-row
                            align="center"
                            justify="end"
                            >
                                <v-btn
                                class="mt-5 mr-10"

                                depressed
                                color="secondary"

                                >
                                View Calender
                                </v-btn>
                            </v-row>
                            </v-card-actions>
-->
                            </v-card>
                    </v-col>
                     <v-col >
                            <v-card outlined elevation="2" color="#ffffff" light height="290">
                            <v-card-title color="primary">Next Lecture</v-card-title>

                                <h1 class="text-left pl-5" v-if="nextLecture">{{nextLecture}}</h1>

                                 <v-card-actions class="justify-end py-5">
                            <v-row
                            align="center"
                            justify="end"
                            >
                                <v-btn
                                class="mr-10"
                                style="margin-top:4.5em"

                                depressed
                                color="primary"
                                    @click="switchComponent('Lecturer Time Table')"
                                >
                                View Timetable
                                </v-btn>
                            </v-row>
                            </v-card-actions>

                            </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-card outlined elevation="2" color="#ffffff" light style=" font-family: Poppins;">
                         <!--   <v-card-title color="primary" class="font-weight-bold" >School Resumption</v-card-title> -->

                                <!--      <p class="text-left px-6 font-weight-medium body-1">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum, animi maxime, ullam esse quis necessitatibus fuga voluptas, dignissimos officiis provident sit temporibus eius eaque enim. Ullam doloremque consectetur odit maiores?</p>
                           <h1 class="text-left pa-3">08:00:00 AM</h1>

                                <v-divider class="px-8" inset></v-divider>

                                <v-card-actions class="justify-end py-5">
                                <v-card-text class="text-left">Posted By: Some Guy</v-card-text>
                                <v-spacer></v-spacer>

                            <v-card-text class="text-right">3 Days Ago</v-card-text>

                            </v-card-actions>-->

                        </v-card>

                    </v-col>
                </v-row>
    </v-container>
  </v-sheet>


  </v-app>
</template>

<script>
import OverviewCards from "../../../OverviewCards";
import UserAPI from "../../../../apis/User";

import TimeTableAPI from "../../../../apis/TimeTable";
import TimeTableLectureAPI from "../../../../apis/TimeTableLecture";
import SessionAPI from "../../../../apis/AcademicSession";
import CourseAllocationAPI from "../../../../apis/CourseAllocation";

export default {
    name : "Overview",
    components: {OverviewCards},

    data() {
        return {
            lecturer: null,
            //---------------------------
            lectureDays:[],
            lecturePeriods:[],
            lectures:[],
            nextLecture:'',
            search:'',
            timeTableLectures:[],
            weekDayList:[],
            periodList:[],
            currentSemester:null,
            myAllocatedCourses:[],
            courseAllocations:[],

           weekdays:['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            today:'',
            tomorrow:'',
        }
    },
    mounted() {
       this.initialize();
    },

    methods: {
        initialize () {
            let now = new Date();
            let weekday = now.getUTCDay();
            this.today = this.weekdays[weekday];
            this.tomorrow = this.weekdays[weekday+1]
            UserAPI.auth().then(response=>{
                  this.lecturer = response.data.data;
            });

        //------------------------------------------------------------------------------------------------------------
            SessionAPI.getAllSemesters()
                .then(response=>{
                    this.currentSemester =  response.data.data.find(semester=>semester.status === 'current');
                })
                .catch(error=>{
                  console.log(error);
            });
        //------------------------------------------------------------------------------------------------------------
            CourseAllocationAPI.getAllCourseAllocations()
                .then(response=>{
                    this.myAllocatedCourses = [];
                    for (const key in response.data.data) {
                        if (Object.hasOwnProperty.call(response.data.data, key)) {
                          const allocation = response.data.data[key];
                          if (allocation.lecturer.id === this.lecturer.id && allocation.session.id === this.currentSemester.session.id){
                            this.myAllocatedCourses.push(allocation.course);
                          }
                        }
                    }
                })
                .catch(error=>{
                  console.log(error);
                });
        //------------------------------------------------------------------------------------------------------------
            TimeTableAPI.getAllTimeTables()
                  .then(response=>{
                    let  currentTimeTable = response.data.data.find(timeTable=>timeTable.faculty.id===this.lecturer.faculty.id
                                                                            && timeTable.semester.id === this.currentSemester.id)
                  if (currentTimeTable) {//if a time table for lecturer's faculty and curent semester is found, load it.
                   // console.log(currentTimeTable) ;
                    //this.loadDataTableHeadersAndColumns(currentTimeTable);
                    this.loadTimeTableLectures(currentTimeTable.id);
                  }

                })
                .catch(error=>{
                  console.log(error);
                });
        //------------------------------------------------------------------------------------------------------------

      },

      //-------------------------------------------------------------------------------------
      loadTimeTableLectures(timeTableID){
          TimeTableLectureAPI.getMyLectures(timeTableID)
            .then(response=>{
              this.lectures = [];
              let counter =0;
             // console.log(response.data.data)
              for (let index = 0; index < response.data.data.length; index++) {
                const lecture = response.data.data[index];
                let allocatedCourse = this.myAllocatedCourses.find(course=>course.id === lecture.course.id);
                if (allocatedCourse && lecture.lectureDay.week_day === this.today ) {//filter out lectures of only courses allocated to the lecturer
                    lecture.lectureDay.week_day = 'Today';
                    this.lectures.push(lecture);
                    counter++;
                }       //|| lecture.lectureDay.week_day === this.tomorrow
                if (counter>2) {
                    break;
                }
              }
              this.nextLecture=this.lectures[0].lecturePeriod.start_time;
              //console.log('lectures: ');
             // console.log(this.lectures);
            })
            .catch(error=>{
              console.log(error);
            })
      },

        switchComponent(comp) {
            this.$router.push({name:comp});
        },
    },
}
</script>

<style>

</style>>
