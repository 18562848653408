import Api from './Api';
import Csrf from "./Csrf";

export default {
    async getProgrammeStudents(programme_id, level_id, session_id, page) {
        await Csrf.getCookie();
        return Api.get('/students/programme-students/' + programme_id + '/' + level_id + '/' + session_id + '?page=' + page);
    },

    async getStudentsRegistration(programme_id, session_id, level_id, per_page, page) {
        await Csrf.getCookie();
        return Api.get('/students/students-registration-list/' + programme_id + '/' + session_id + '/' + level_id + '/' + per_page + '?page=' + page);
    },

    async getStudentsRegistrationSubmission(programme_id, session_id, level_id, page, per_page) {
        await Csrf.getCookie();
        return Api.get('/students/students-registration-submission-list/' + programme_id + '/' + session_id + '/' + level_id + '/' + per_page + '?page=' + page);
    },
    async getDepartmentalCourseRegistrations(programme_id, session_id, level_id, page) {
        await Csrf.getCookie();
        return Api.get('/students/departmental-course-registrations/' + programme_id + '/' + session_id + '/' + level_id + '?page=' + page);
    },

    async getStudentsCourseEnrollment(course_id, session_id, level_id, page, per_page) {
        await Csrf.getCookie();
        return Api.get('/students/students-course-enrollment-list/' + course_id + '/' + session_id + '/' + level_id + '/' + per_page + '?page=' + page);
    },

    async getMyResultsSpreadSheet(student_id) {
        await Csrf.getCookie();
        return Api.get('/students/student-results-spreadsheet/' + student_id);
    },

    async getCourseRegistrationsList(student_id) {
        await Csrf.getCookie();
        return Api.get('/student/course-registrations-list/' + student_id);
    },

    async checkRegistrationPaymentStatus(student_id) {
        await Csrf.getCookie();
        return Api.get('/students/registration-payment/status/' + student_id);
    },

    async checkPaymentStatus(student_id, session_id) {
        await Csrf.getCookie();
        return Api.get('/students/payment/status/' + student_id + '/' + session_id);
    },

    async updateCourseRegistration(student_id, courses) {
        await Csrf.getCookie();
        return Api.patch('/students/' + student_id + '/course-registration/update', courses);
    },

    async viewCourseForm(student_id, session_id) {
        await Csrf.getCookie();
        return Api.get('/students/' + student_id + '/course-registration/session/' + session_id + '/course-form');
    },

    async previewCourseForm(student_id, session_id) {
        await Csrf.getCookie();
        return Api.get('/students/' + student_id + '/course-registration/session/' + session_id + '/course-form/preview');
    },
    async getStudentsInFaculty(faculty_id) {
        await Csrf.getCookie();
        return Api.get('/allStudents/faculty/' + faculty_id);
    },

    //------------------ APIs for downloading printouts -----------

    async downloadPrintout(url) {
        // await Csrf.getCookie();
        return Api.get(url, { responseType: 'blob' });
    },

    async getMyCurrentCourseSpecifications() {
        await Csrf.getCookie();
        return Api.get('/student/current-course-specifications');
    },

    async getMyCurrentSemesterCourseSpecifications(session_id, level_id, programme_id, semester_id) {
        await Csrf.getCookie();
        return Api.get('/student/current-semester-course-specifications/' + session_id + '/' + level_id + '/' + programme_id + '/' + semester_id);
    },

    async getMyResults(data) {
        await Csrf.getCookie();
        return Api.patch('/students/student-results', data);
    },

    async downloadResults(student_id, session_id, programme_id) {
        return Api.get('/students/student-results/download/' + student_id + '/' + session_id + '/' + programme_id, { responseType: 'blob' });
    },

    async downloadStudentTranscript(student_id) {
        return Api.get('/students/download-transcript/' + student_id, { responseType: 'blob' });
        // return Api.get('/students/download-transcript/' + student_id);
    },

    async getCreditLoadLimitByProgramme(programme_id, level_id) {
        await Csrf.getCookie();
        return Api.get('/student/credit-load-limit-by-programme-and-level/' + programme_id + '/' + level_id);
    },

    async getAvailableHostels() {
        await Csrf.getCookie();
        return Api.get('/student/hostels/available');
    },

    async makeHostelReservation(hostel_block_allocation_id) {
        await Csrf.getCookie();
        return Api.post('/student/hostel/reservation/reserve/' + hostel_block_allocation_id);
    },

    async getMyHostelReservation() {
        await Csrf.getCookie();
        return Api.get('/student/hostel/my-reservation');
    },

    async getMyHostelAllocation() {
        await Csrf.getCookie();
        return Api.get('/student/hostel/my-allocation');
    },

    async releaceHostelReservation(reservation_id) {
        await Csrf.getCookie();
        return Api.post('/student/hostel/reservation/release/' + reservation_id);
    },

    async getBedSpacesInformation() {
        await Csrf.getCookie();
        return Api.get('/bed-space/get-bedspaces-information');
    },

    async getStudentByID(student_id) {
        await Csrf.getCookie();
        return Api.get('/get-single-student/' + student_id)
    },

    async getStudentCandidacyByID(student_id) {
        await Csrf.getCookie();
        return Api.get('/get-student-candidacy/' + student_id)
    },

    async getStudentCandidacyResultsByID(candidate_id) {
        await Csrf.getCookie();
        return Api.get('/get-student-candidacy-results/' + candidate_id)
    },

    async checkAccomodationPaymentStatus(student_id, session_id) {
        await Csrf.getCookie();
        return Api.get('/student-accomodation-payment-status/' + student_id + '/' + session_id);
    },

    async downloadHostelFormSlip(url) {
        // await Csrf.getCookie();
        return Api.get(url, { responseType: 'blob' });
    },

    async searchStudents(search_string) {
        await Csrf.getCookie();
        return Api.get('/students/search/' + search_string)
    },

    //------------------ APIs for resetting biometrics -----------
    async resetSignature(student_id) {
        await Csrf.getCookie();
        return Api.patch('/signature/reset/' + student_id);
    },
    async resetProfilePicture(student_id) {
        await Csrf.getCookie();
        return Api.patch('/profile-picture/reset/' + student_id);
    },

    async checkRegistrationPaymentStatusBySession(student_id, session_id) {
        await Csrf.getCookie();
        return Api.get('/get-registration-payment-status-by-session/' + student_id + '/' + session_id);
    },

    async getStudentsByProgrammeAndLevelID(programme_id, level_id) {
        await Csrf.getCookie();
        return Api.get('/all-programme-level-students/' + programme_id + '/' + level_id)
    },

    async MatricExemptionTemplateExport() {
        return Api.get('/matric-number-exemption/export/template', { responseType: 'blob' })
    }
};
