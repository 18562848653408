/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
*/

require('./bootstrap');
import SvgVue from 'svg-vue';

import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css';
// import VueRouter from 'vue-router';

import router from './router';
import Vue from 'vue';

import InstitutionAPI from "./apis/Institution";

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);

//  import VueCryptojs from 'vue-cryptojs'

//  Vue.use(VueCryptojs)
/**
 * adding pinia for global storage of users' roles & permissions
 */
import { createPinia, PiniaVuePlugin } from 'pinia';
   Vue.use(PiniaVuePlugin);
   const pinia = createPinia();
   //---persistt store accross page reloads
   pinia.use((context) =>{
       const storeId = context.store.$id;
       const serializer = {
           serialize: JSON.stringify,
           deserialize: JSON.parse
       };


       //--load storage from sessionStorage
       const persisted_storage = serializer.deserialize(window.localStorage.getItem(storeId))
       if (persisted_storage) {
           // const decryptedState = $CryptoJS.AES.decrypt(persisted_storage, cryptoAESKey).toString($CryptoJS.enc.Utf8)
           // console.log(decryptedState)
           context.store.$patch(persisted_storage);
       }

       //--listen to changes and update sessionStorage
       context.store.$subscribe((mutation, state)=>{
           // const encryptedState = $CryptoJS.AES.encrypt(serializer.serialize(state), cryptoAESKey).toString()
           // console.log(encryptedState)
           window.localStorage.setItem(storeId, serializer.serialize(state))
       });
   });



Vue.use(SvgVue);

window.Vue = require('vue').default;
// Vue.use(VueRouter);


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */




// Vue.component('request-email', require('./components/Views/PasswordReset/RequestEmail.vue').default);

//  Vue.component('VueRecaptcha', require('vue-recaptcha').default);

 Vue.component('reset-password', require('./components/Views/PasswordReset/ResetPassword.vue').default);
 Vue.component('reset-success', require('./components/Views/PasswordReset/ResetSuccess.vue').default);
 Vue.component('reset-failure', require('./components/Views/PasswordReset/ResetFailure.vue').default);

 Vue.component('enrollment-form', require('./components/Views/Enrollment/EnrollmentMultistepForm.vue').default);
 Vue.component('t-breadcrumbs', require('./components/Views/CustomBreadCrumbs.vue').default);
 import Toast from "./components/Toast.vue";
 Vue.component('Toast', Toast);
 

Vue.filter('trailing_zero', val => {
    if (!val || isNaN(parseInt(val))) return '';
    if (parseInt(val) < 10) return '0' + val;
});

 import { useUserStore } from "./stores/userStore";
 import { useLevelAdvisingStore } from "./stores/levelAdvisingStore";
 import { useInstitutionStore } from "./stores/institutionStore";
 import { mapStores } from 'pinia';
 
 Vue.mixin(
    {
   data() {
            return {
           
           theme_is_loaded: false,
           bg_color: sessionStorage.getItem('primaryColor'),
           overlay: false,
           toast_data:{
            text: '',
            color: '',
            time: null
           },
           
           create_update_loading: false,
           delete_loading: false,
           cancel_button_disabled:false,
       }
   },
    computed: {
       ...mapStores(useUserStore),
       ...mapStores(useLevelAdvisingStore),
        ...mapStores(useInstitutionStore),

        bucketUrl() {
            return process.env.MIX_BUCKET_URL;
        },

       reCaptchaSiteKey() {
           return process.env.MIX_GOOGLE_RECAPTCHA_SITE_KEY;
       },

       cryptoAESKey() {
           return process.env.MIX_CRYPTO_AES_KEY;
       },
    },

    mounted: function(){
       if(sessionStorage.getItem('primaryColor') != undefined || sessionStorage.getItem('primaryColor') != null){
           let theme = {
               'primary':  sessionStorage.getItem('primaryColor'),
               'secondary': sessionStorage.getItem('secondaryColor'),
               'accent':  sessionStorage.getItem('accentColor'),
               'tertiary':  sessionStorage.getItem('tertiaryColor'),
               'textColor': sessionStorage.getItem('textColor'),
           }
           this.loadThemes(theme);
       }else{
           this.verifySubdomain();
       }
       this.bg_color = sessionStorage.getItem('primaryColor');
    },

    methods: {
       loadThemes(institution_theme){
          if (this.$vuetify.theme.themes) {
           this.$vuetify.theme.themes.light.primary = institution_theme.primary;
           sessionStorage.setItem('primaryColor', institution_theme.primary);
           this.$vuetify.theme.themes.light.secondary = institution_theme.secondary;
           sessionStorage.setItem('secondaryColor', institution_theme.secondary);
           this.$vuetify.theme.themes.light.accent = institution_theme.accent;
           sessionStorage.setItem('accentColor', institution_theme.accent);
           this.$vuetify.theme.themes.light.tertiary = institution_theme.tertiary;
           sessionStorage.setItem('tertiaryColor', institution_theme.tertiary);
           this.$vuetify.theme.themes.light.textColor = institution_theme.text_color;
           sessionStorage.setItem('textColor', institution_theme.text_color);
           this.theme_is_loaded = true;
          }
       },

       verifySubdomain: function(){
       if(this.theme_is_loaded){
           return;
       }
       InstitutionAPI.verifySubdomain()
           .then(response => {
           this.loadThemes(response.data.institution.theme);
       })
           .catch(error => {
           });
       },

       getContrastColor: function() {
           const colorElement = document.createElement("div");
           colorElement.style.color = this.bg_color;
           document.body.appendChild(colorElement);
           const computedColor = window.getComputedStyle(colorElement).color;
           document.body.removeChild(colorElement);

           // Convert computed color to RGB
           const rgbMatch = computedColor.match(/\d+/g);
           if (rgbMatch) {
             const [r, g, b] = rgbMatch.map(Number);

             // Calculate perceived brightness
             const brightness = (r * 299 + g * 587 + b * 114) / 1000;
             // Prioritize black on light colors and white on deep colors
             return brightness >= 50 ? "text-dark" : "text-light";
           }

           // Default to 'text-dark' if RGB extraction fails
           return "text-dark";
        },
       
        handleErrorToast(error) {
            if (typeof error === "string") {
                this.toast('warning', error)
            }
            else {
                
                let status = error.response?.status;
                let errors = error.response?.data.message;
                let error_text = error.response?.statusText ;
                (status && status >= 400 && status < 500) ? this.toast('warning', errors) : this.toast('error', error_text);
            }
                this.stopLoading();
        },

        handleSuccessToast(response, flag = null) {
            
            typeof response === "string"? this.toast('success', response): this.toast('success', response.data.message); this.stopLoading();
            
            flag ? this.close(flag) : this.close()
            
            typeof this.closeDelete === 'function' ? this.closeDelete() : null;

        },
          
           //------------------ snackbar trigger-----------------------------------
        toast(color, text) {
        this.toast_data.color = color;
        this.toast_data.text = text;
        this.toast_data.time = new Date().getTime();
  
        },
        stopLoading() {
            this.create_update_loading = false
            this.delete_loading = false
            this.cancel_button_disabled = false
         },

        async downloadProtectedData(api_url, download_file_name, file_type) {
            const token = localStorage.getItem('tertiary_token');     
            try {
                //this.overlay = true;
                const response = await axios.get(
                    api_url, 
                    {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    },
                    responseType:"blob"
                });

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                switch (file_type) {
                    case 'pdf':
                    case 'PDF':
                    case 'Pdf':
                        link.setAttribute("download", download_file_name + ".pdf");
                        break;
                    case 'Excel':
                    case 'excel':
                    case 'EXCEL':
                        link.setAttribute("download", download_file_name + ".xlsx");
                        break;
                    default:
                        link.setAttribute("download", download_file_name + ".pdf");
                        break;
                }
                //link.setAttribute("download", this.download_file_name + ".pdf"); // Specify the name of the file to be downloaded
                document.body.appendChild(link);
                link.click();
                //this.overlay = false;
            } catch (error) {
              // Handle errors
              alert('Error: downloading failed');
            }
        },
    },
})


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    vuetify,
    router,
    pinia
});
