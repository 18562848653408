import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => {
    return { 
      role_names: [],
      permission_names: [],
      auth_user:{},
      subdomain:''
    };
  },
  actions: {
    setRoleNames(role_name_list) {
      this.role_names = role_name_list;
    },

    setPermissionNames(permission_name_list) {
      this.permission_names = permission_name_list;
    },

    setUser(user) {
      this.auth_user = user;
    },

    setSubdomain(subdomain) {
      this.subdomain = subdomain;
    },

    can(permission){
      return this.role_names.includes('Super Admin')||this.permission_names.includes(permission);
    },
  },
  getters: {
    auth(state){
      return state.auth_user;
    },
    getRoles(state){
      return state.role_names;
    },
    getPermissions(state){
      return state.permission_names;
    },

    getSubdomain(state){
      return state.subdomain;
    },
  },
});
